import {Component, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card, Checkbox, Col, Divider, Form, Input, message, Row, Select, Space, Spin, Tag, FloatButton} from "antd";
import Apiurl from "../ConstURL";
import {
    EditFilled,
    LoadingOutlined,
    PlusCircleOutlined,
    RollbackOutlined,
    SaveFilled,
    SaveOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../components/Header";

// eslint-disable-next-line no-undef


export default function Users() {
    const [equipment, equipmentset] = useState([])
    const [hasmore, hasmoreset] = useState(false)
    const [selectedtown, selectedtownset] = useState("")

    const [towns, townsset] = useState([])

    const [editid, editidset] = useState("")

    const Navigate = useNavigate()

    function EditElement(id) {
        const idx = equipment.findIndex((el) => el.Id === id)
        let newusers = equipment.slice()

        if (newusers[idx].Id !== localStorage.getItem("userid")) {
            newusers[idx].Edit = !newusers[idx].Edit
            newusers[idx].Buttonsaveloading = false


            equipmentset(newusers)
        } else {

        }
    }

    function fetchMoreData() {
        fetch(Apiurl + "/user/allusers?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            town: selectedtown,
            skip: equipment.length,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.data)


                let newusers = equipment.slice()


                equipmentset([...newusers, ...data.data])
                if (data.allcount > equipment.length) {
                    hasmoreset(true)
                } else {
                    hasmoreset(false)
                }
            })
    }

    async function SaveElementUserEdit(id) {
        const idx = equipment.findIndex((el) => el.Id === id.userid)
        EditElement(id.userid)
        let adminstatus = ""
        if (id.admin) {
            adminstatus = "Yes"
        } else {
            adminstatus = "No"
        }


        if (equipment[idx].Banned !== id.banned && !equipment[idx].Superadmin) {
            let mesban = ""
            if (id.banned) {
                mesban = "Yes"
            } else {
                mesban = "No"
            }
            await fetch(Apiurl + "/admin/user/ban?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                userid: equipment[idx].Id,
                ban: mesban
            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (!data.status) {
                        message.error("Поле администратор не изменено!")
                    } else {
                        equipment[idx].Banned = id.banned
                    }
                })
        } else {

        }


        await fetch(Apiurl + "/user/edit?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            userid: equipment[idx].Id,

            username: id.username,
            phonenumber: id.phone,
            town: id.town,
            tgid: id.tgid,
            admin: adminstatus

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    message.success("Изменения сохранены!")
                    equipment[idx].Id = id.userid
                    equipment[idx].Username = id.username
                    equipment[idx].Phonenumber = id.phone
                    equipment[idx].Town = id.town
                    equipment[idx].Telegram_id = id.tgid
                    equipment[idx].Admin = id.admin
                    equipmentset(equipment)


                } else {
                    message.error("Ошибка!")
                }
            })


    }

    const validatePhoneNumber = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            if (!value) {
                //await reject('Введите телефон!')
            } else if (!/^((\+7|7|8)+([0-9]){10})$/.test(value)) {
                await reject('Это не номер телефона')
            } else {
                await resolve()
            }
        })
    }

    function handleChange(e) {
        fetch(Apiurl + "/user/allusers?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            town: e,
            skip: 0,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                selectedtownset(e)
                console.log(data.data)
                if (data.allcount > 15) {
                    hasmoreset(true)
                }

                equipmentset(data.data)
            })
    }

    useEffect(() => {
        fetch(Apiurl + "/user/allusers?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.data)

                if (data.allcount > 15) {
                    hasmoreset(true)
                }
                equipmentset(data.data)

            })

        fetch(Apiurl + "/towns?token=" + localStorage.getItem("token"))
            .then(response => {
                return response.json()
            })
            .then(data => {
                let masready = []
                masready.push({value: "", label: "-"})
                data.data.map(town => (
                    masready.push({value: town, label: town})
                ))
                townsset(masready)
            })

    }, [])

    return (
        <div>
            <Header/>
            <Row style={{padding: "20px"}}>
                <Col span={12}>
                    <Select
                        defaultValue="-"
                        style={{width: 200}}
                        onChange={handleChange}
                        options={towns}
                    />
                </Col>
                <Col span={12} style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button type='primary' onClick={(e) => Navigate('/addusers')} icon={<PlusCircleOutlined/>}> Добавить
                        пользователя</Button>
                </Col>

            </Row>
            <InfiniteScroll
                dataLength={equipment.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <div style={{padding: 10, paddingBottom: 25}}><Spin size={"large"}></Spin></div>
                </div>}
                endMessage={
                    <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: 25}}><Card
                        size={"small"}>Больше нет</Card></div>
                }
            >
                <Space type="flex" wrap size='middle' justify="center" align="middle" direction="horizontal"
                       style={{padding: "20px"}}>

                    {equipment.map((user, index) =>
                        <Card key={user.Id}
                              title={user.Username}
                              style={{minWidth: 325, maxWidth: 325, minHeight: 275}}


                              extra={user.Id !== localStorage.getItem("userid") &&
                                  <div>{user.Edit ? <Button shape={"circle"} onClick={(e) => EditElement(user.Id)}
                                                            icon={<RollbackOutlined/>}></Button> :
                                      <Button shape={"circle"} onClick={(e) => EditElement(user.Id)}
                                              icon={<EditFilled/>}></Button>}</div>}>

                            {user.Edit ?
                                <div>
                                    <Form
                                        onFinish={SaveElementUserEdit}
                                    >
                                        <Form.Item
                                            name="userid"
                                            initialValue={user.Id}
                                            hidden={true}
                                        >

                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label="ФИО"
                                            name="username"
                                            initialValue={user.Username}
                                        >

                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label="Телефон:"
                                            name="phone"
                                            initialValue={user.Phonenumber}
                                            rules={[

                                                {validator: validatePhoneNumber}

                                            ]}>

                                            <Input/>
                                        </Form.Item>

                                        <Form.Item label="Город"
                                                   name="town"
                                                   initialValue={user.Town}
                                        >
                                            <Select rules={[
                                                {
                                                    required: true,
                                                    message: 'Выберите город!',
                                                },
                                            ]}>
                                                <Select.Option value="Иркутск">Иркутск</Select.Option>
                                                <Select.Option value="Новосибирск">Новосибирск</Select.Option>
                                                <Select.Option value="Хабаровск">Хабаровск</Select.Option>
                                                <Select.Option value="Улан-Удэ">Улан-Удэ</Select.Option>
                                                <Select.Option value="Чита">Чита</Select.Option>
                                                <Select.Option value="Новороссийск">Новороссийск</Select.Option>
                                            </Select>

                                        </Form.Item>

                                        <Form.Item
                                            label="Telegram id:"
                                            name="tgid"
                                            initialValue={user.Telegram_id}>
                                            <Input/>
                                        </Form.Item>
                                        {(localStorage.getItem("superadmin") === "yes" && !user.Superadmin) &&
                                            <Form.Item
                                                name="admin"
                                                valuePropName="checked"
                                                initialValue={user.Admin}

                                            >
                                                <Checkbox>Администратор</Checkbox>
                                            </Form.Item>}
                                        {(localStorage.getItem("superadmin") === "yes" && !user.Superadmin) &&
                                            <Form.Item
                                                name="banned"
                                                valuePropName="checked"
                                                initialValue={user.Banned}

                                            >
                                                <Checkbox>Заблокирован</Checkbox>
                                            </Form.Item>}

                                        <Form.Item
                                            wrapperCol={{

                                                span: 20,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit" icon={<SaveFilled/>}>
                                                Сохранить
                                            </Button>
                                        </Form.Item>
                                    </Form>


                                </div>

                                :
                                <div>
                                    <Row>Email: {user.Email}</Row>
                                    <Row>Телефон: {user.Phonenumber}</Row>
                                    <Row>Город: {user.Town}</Row>
                                    <Row>Telegram Id: {user.Telegram_id}</Row>

                                    <Divider/>{user.Admin && <Tag color={"green"}>Администратор</Tag>} {user.Banned &&
                                    <Tag color={"red"}>ЗАБЛОКИРОВАН</Tag>}
                                </div>}

                        </Card>
                    )}

                </Space>
                <FloatButton.BackTop />
            </InfiniteScroll>
        </div>
    );

}