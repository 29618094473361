import React, {useEffect, useState} from 'react';
import {
    PlusCircleOutlined,
    UserOutlined,
    MailOutlined,
    SettingOutlined,
    CarOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import {useNavigate} from "react-router-dom";
import Apiurl from "../ConstURL";

const items = [
    {
        label: 'Главная',
        key: 'main',
        icon: <MailOutlined/>,
    },
    {
        label: 'Пользователи',
        key: 'users',
        icon: <UserOutlined />,
        disabled: false,
    },
    {
        label: 'Транспортные средства',
        key: 'transport',
        icon: <CarOutlined />,
        disabled: false,
    },
    {
        label: 'Статистика ',
        key: 'users/static',
        icon: <LineChartOutlined />,
        disabled: false,
    },

];


export default function Header (){



    const navigate = useNavigate()
    const [current, setCurrent] = useState(localStorage.getItem("currentpage") ==="" ? "main" : localStorage.getItem("currentpage"));

    useEffect(() => {

        // if(localStorage.getItem("currentpage")!==""){
        //     setCurrent(localStorage.getItem("currentpage"));
        //
        // }

    }, [])


        const onClick = (e) => {
            setCurrent(e.key);
            localStorage.setItem("currentpage",e.key)
            navigate("/"+e.key)

        };
        return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}/>;

}