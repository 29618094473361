import {Component, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Card,
    Checkbox,
    ConfigProvider,
    DatePicker,
    Form,
    Input,
    Layout,
    message,
    Row,
    Select,
    Space
} from "antd";
import Apiurl from "../ConstURL";
import {ArrowLeftOutlined, CloseOutlined, FileExcelOutlined, RollbackOutlined} from "@ant-design/icons";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import Header from "../components/Header";
const { RangePicker } = DatePicker;



dayjs.locale('ru');
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledHours: () => range(0, 60).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledHours: () => range(0, 60).splice(20, 4),
        disabledMinutes: () => range(0, 31),
        disabledSeconds: () => [55, 56],
    };
};
export default function Staticusers() {

    const rangePresets = [
        {
            label: 'За 7 дней',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'За 14 дней',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'За 30 дней',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'За 90 дней',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const [form] = Form.useForm()

    const onFinish = (values) => {
        var token = localStorage.getItem("token")
        console.log(values)
        console.log(values.dates[0].format('YYYY-MM-DD'))
        console.log(values.dates[1].format('YYYY-MM-DD'))
        console.log(values.checkadmin ? "1" : "0")
        fetch(Apiurl+"/admin/getstats/excel?" + new URLSearchParams({
            token: token,
            from_time: values.dates[0].format('YYYY-MM-DD'),
            to_time: values.dates[1].format('YYYY-MM-DD'),
            with_admins: values.checkadmin ? "1" : "0"
        }))
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'users_static_'+values.dates[0].format("DD.MM.YYYY")+'-'+values.dates[1].format("DD.MM.YYYY")+'.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

            });
    }



    const [Createduser, setCreated] = useState(false)
    const [validphone, setvalidphone] = useState("error")

    if (!Createduser) {


        return (<div>
                <Header/>
                <Row type="flex" justify="center" align="middle" style={{minHeight: '50vh', paddingTop: '20px'}}>
                    <Card type="flex" title={"Статистика выполненых работ"} justify="center" align="middle" >


                        {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                        {/*Create User*/}
                        <Form
                            layout='vertical'
                            style={{justifyContent: 'center'}}
                            name="basic"
                            form={form}
                            wrapperCol={{

                            }}
                            style={{
                                width: '325px',
                                verticalAlign: 'middle',


                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            // onFinishFailed={}
                            autoComplete="off"
                        >
                            <Form.Item
                                layout={"vertical"}
                                label="Выберите промежуток времени:"
                                name="dates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Выберите даты!',
                                    },
                                ]}
                            >

                                <RangePicker disabledTime={disabledRangeTime} format="DD.MM.YYYY" presets={rangePresets} />

                            </Form.Item>



                            <Form.Item label="Выгрузить с администраторами:"
                                       name="checkadmin" valuePropName="checked" wrapperCol={{

                                span: 1,
                            }}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{

                                    span: 1,
                                }}
                            >
                                <Button type="primary" htmlType="submit" size='middle' icon={<FileExcelOutlined />}>
                                    Выгрузить статистику
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Row>
            </div>
        );
    } else {
        return (

            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center', paddingTop: '25px'}}>
                <Button type='primary' size='large' shape='round'
                        onClick={(e) => setCreated(!Createduser)}>Создать</Button>
                !!!!!
            </Space>

        )
    }

}