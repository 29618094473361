import {Component, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useShowPopup } from '@vkruglikov/react-telegram-web-app';
import {Button, Card, Collapse, Form, Input, InputNumber, message, Row, Select, Space} from "antd";
import Apiurl from "../ConstURL";
import {useNavigate} from "react-router-dom";

const tg = window.Telegram.WebApp;
export default function Service_ChangeSKZI_Page() {
    const [form] = Form.useForm()
    const Navigate = useNavigate()

    const [successbool, successboolset] = useState(true)
    const [errbool, errboolset] = useState(true)

    const [initialdata, initialdataset] = useState([])
    const [token, tokenset] = useState("")

    const validateSerialSKZI = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            if (!value) {
                //await reject('Введите телефон!')
            } else if (!/^[a-zA-Z0-9]{16}-[a-zA-Z0-9]{16}$/.test(value)) {
                await reject('Это не серийный номер СКЗИ')
            } else {
                fetch(Apiurl + "/services/check/skzi?" + new URLSearchParams({
                    token: token,
                    skzi: value
                }))
                    .then(response => {
                        return response.json()
                    })
                    .then(async data => {
                        if (data.status) {
                            await resolve()
                        }else {
                            await reject('Данный серийный номер уже есть в базе!')
                        }
                    })

            }
        })
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const onFinish = (values) => {
        const paramsUrl = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        console.log(values)
        console.log(values?.Customer ? values?.Customer : "")
        // tg.sendData("check1111")

        fetch(Apiurl + "/services/tachographskzi/changeskzi?" + new URLSearchParams({
            token: token,
            VIN: paramsUrl.VIN,

            serial_skzi: values.serial_skzi,
            odometer: values.odometr,
            W:values.W,
            K: values.K,
            L: values.L,

            Owner_name: values?.Owner_name ? values?.Owner_name : "",
            Owner_addres: values?.Owner_addres ? values?.Owner_addres : "",
            Customer: values?.Customer ? values?.Customer : "",
            Customer_addres: values?.Customer_addres ? values?.Customer_addres : "",
            Transport_gos_number: values?.Transport_gos_number ? values?.Transport_gos_number : "",
        }))
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if (data['status']) {
                    let result = {"type":"changeskzi","status":true,"vin":paramsUrl.VIN}
                    tg.sendData(JSON.stringify(result))
                    initialdataset([])
                    successboolset(false)
                    sleep(2000).then(r => {
                        tg.close()
                        form.resetFields()
                    })



                    // e.preventDefault();
                    // e.target.reset();

                } else {
                    let result = {"type":"changeskzi","status":false,"vin":paramsUrl.VIN}
                    tg.sendData(JSON.stringify(result))
                    errboolset(false)
                    sleep(3000).then(r => {
                        tg.close()
                        form.resetFields()
                    })
                }
            }
        )

        //window.alert("Авторизация прошла успешно!")
    };

    useEffect(() => {
        const script = document.createElement('script');


        tg.expand();

        const paramsUrl = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        tokenset(paramsUrl.token)

        fetch(Apiurl + "/transport/find/vin?" + new URLSearchParams({
            token: paramsUrl.token,
            VIN: paramsUrl.VIN
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                let masready = []
                masready.push(data.data)
                initialdataset(masready)
                console.log(initialdata)
            })


    }, [])


    return (


            <Space type="flex" size='middle' justify="center" align="center" direction="vertical" style={{padding: "20px"}}>
                <Card style={{width:"90vw"}} hidden={successbool} justify="center" align="middle">
                    <div>Услуга успешно оказана!</div>
                    <div style={{paddingTop:"15px"}}>Документы обновлены!</div>

                    </Card>
                <Card style={{width:"90vw"}} hidden={errbool} justify="center" align="middle">
                    <div>Услуга не оказана, произошла ошибка!</div>

                </Card>

                {initialdata.map((data,index)=>(
                <Card style={{width:"90vw"}} title={"Замена блока СКЗИ"} justify="center" align="middle">
                    <Form
                        layout='horizontal'
                        style={{justifyContent: 'center'}}
                        name="basic"
                        form={form}
                        wrapperCol={{
                            span: '85%',
                        }}
                        style={{


                            verticalAlign: 'middle',


                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        // onFinishFailed={}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Серийный номер СКЗИ"
                            name="serial_skzi"
                            initialValue={data?.Tachograph_SKZI_data?.Serial_SKZI}
                            rules={[
                                {
                                    required: true,
                                    message: 'Серийный номер СКЗИ!',
                                },
                                {validator: validateSerialSKZI}
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Одометр"
                            name="odometr"
                            initialValue={data?.Odometer}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите значение одометра!',
                                },
                            ]}
                        >
                            <InputNumber controls={true} style={{width:"100%"}} min={1} step={1000}/>
                        </Form.Item>

                        <Form.Item
                            label="W"
                            name="W"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите значение W!',
                                },
                            ]}
                        >
                            <InputNumber controls={true} style={{width:"100%"}} min={1} step={1}/>
                        </Form.Item>

                        <Form.Item
                            label="K"
                            name="K"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите значение K!',
                                },
                            ]}
                        >
                            <InputNumber controls={true} style={{width:"100%"}} min={1} step={1}/>
                        </Form.Item>

                        <Form.Item
                            label="L"
                            name="L"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите значение L!',
                                },
                            ]}
                        >
                            <InputNumber controls={true} style={{width:"100%"}} min={1} step={1}/>
                        </Form.Item>




                        <Collapse style={{marginBottom:"25px"}}
                        items={[{
                            key: 1,
                            label: "Данные о ТС, владельце, заказчике.",
                            children:
                                <div>
                                    <Form.Item
                                        label="ФИО владельца"
                                        name="Owner_name"
                                        initialValue={data?.Owner_name}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите ФИО владельца!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Адрес владельца"
                                        name="Owner_addres"
                                        initialValue={data?.Owner_addres}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите адрес владельца!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="ФИО заказчика"
                                        name="Customer"
                                        initialValue={data?.Customer}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите ФИО заказчика!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        initialValue={data?.Customer_addres}
                                        label="Адрес заказчика"
                                        name="Customer_addres"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите адрес заказчика!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Гос. номер"
                                        initialValue={data?.Transport_gos_number}
                                        name="Transport_gos_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите Гос. номер!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                </div>


                        }]}>
                        </Collapse>


                        <Form.Item
                            wrapperCol={{

                                span: 1,
                            }}
                        >
                            <Button type="primary" htmlType="submit" size='large'>
                                Оказать услугу
                            </Button>
                        </Form.Item>
                    </Form>


                </Card>)
                    )}
            </Space>

    );

}