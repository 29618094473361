import {Component, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useShowPopup} from '@vkruglikov/react-telegram-web-app';
import {Button, Card, Collapse, Form, Image, Input, InputNumber, message, Popover, Row, Select, Space, Tag} from "antd";
import Apiurl from "../ConstURL";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";

const tg = window.Telegram.WebApp;
export default function Service_Calibration_Page() {

    const Navigate = useNavigate()

    const [mashistory, mashistoryset] = useState([])
    const [masterinfo, masterinfoSet] = useState({})

    const [token, tokenset] = useState("")

    async function GetDataMaster(userid) {
        await fetch(Apiurl + "/user/findid?" + new URLSearchParams({
            token: token,
            userid: userid,
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data['status']) {
                    masterinfoSet(data['data'])
                }

            })

    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function GetInfo(data) {
        if (data.ID.split(":")[0] === "TACHOGRAPHSKZI") {
            return (
                <div>
                    <div>Одометр: {data.Odometr}</div>
                    <div>Оказал услугу:
                        <Popover onCl content={
                            <div>
                                <Row>Email: {masterinfo.Email}</Row>
                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                <Row>Город: {masterinfo.Town}</Row>
                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                            </div>
                        } title={masterinfo.Username} trigger="click">
                            <Tag
                                onClick={(e) => GetDataMaster(data.Master_id)}>Мастер
                            </Tag></Popover>
                    </div>
                    <div>Модель: {data.Model}</div>
                    <div>Производитель: {data.Manufacturer}</div>
                    <div>Серийный номер: {data.Serial_number}</div>
                    <div>Серийный номер СКЗИ: {data.Serial_SKZI}</div>
                    <div>W: {data.W===-1 ? "-" : data.W}</div>
                    <div>K: {data.K===-1 ? "-" : data.K}</div>
                    <div>L: {data.L===-1 ? "-" : data.L}</div>

                </div>
            )
        }

        if (data.ID.split(":")[0] === "TACHOGRAPHEU") {
            return (
                <div>
                    <div>Одометр: {data.Odometr}</div>
                    <div>Оказал услугу:
                        <Popover onCl content={
                            <div>
                                <Row>Email: {masterinfo.Email}</Row>
                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                <Row>Город: {masterinfo.Town}</Row>
                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                            </div>
                        } title={masterinfo.Username} trigger="click">
                            <Tag
                                onClick={(e) => GetDataMaster(data.Master_id)}>Мастер
                            </Tag></Popover>
                    </div>
                    <div>Модель: {data.Model}</div>
                    <div>Производитель: {data.Manufacturer}</div>
                    <div>Серийный номер: {data.Serial_number}</div>
                    <div>W: {data.W===-1 ? "-" : data.W}</div>
                    <div>K: {data.K===-1 ? "-" : data.K}</div>
                    <div>L: {data.L===-1 ? "-" : data.L}</div>

                </div>
            )
        }

        if (data.ID.split(":")[0] === "TACHOGRAPHSKZICHANGE") {
            return (
                <div>
                    <div>Одометр: {data.Odometr}</div>
                    <div>Оказал услугу:
                        <Popover onCl content={
                            <div>
                                <Row>Email: {masterinfo.Email}</Row>
                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                <Row>Город: {masterinfo.Town}</Row>
                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                            </div>
                        } title={masterinfo.Username} trigger="click">
                            <Tag
                                onClick={(e) => GetDataMaster(data.Master_id)}>Мастер
                            </Tag></Popover>
                    </div>
                    <div>Модель: {data.Model}</div>
                    <div>Производитель: {data.Manufacturer}</div>
                    <div>Серийный номер: {data.Serial_number}</div>
                    <div>Серийный номер СКЗИ: {data.Serial_SKZI}</div>
                    <div>W: {data.W===-1 ? "-" : data.W}</div>
                    <div>K: {data.K===-1 ? "-" : data.K}</div>
                    <div>L: {data.L===-1 ? "-" : data.L}</div>

                </div>
            )
        }

        if (data.ID.split(":")[0] === "TACHOGRAPHCALIBRATION") {
            return (
                <div>
                    <div>Одометр: {data.Odometr}</div>
                    <div>Оказал услугу:
                        <Popover onCl content={
                            <div>
                                <Row>Email: {masterinfo.Email}</Row>
                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                <Row>Город: {masterinfo.Town}</Row>
                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                            </div>
                        } title={masterinfo.Username} trigger="click">
                            <Tag
                                onClick={(e) => GetDataMaster(data.Master_id)}>Мастер
                            </Tag></Popover>
                    </div>
                    <div>W: {data.W===-1 ? "-" : data.W}</div>
                    <div>K: {data.K===-1 ? "-" : data.K}</div>
                    <div>L: {data.L===-1 ? "-" : data.L}</div>

                </div>
            )
        }

        if (data.ID.split(":")[0] === "TERMINAL") {
            return (
                <div>
                    <div>Одометр: {data.Odometr}</div>
                    <div>Оказал услугу:
                        <Popover onCl content={
                            <div>
                                <Row>Email: {masterinfo.Email}</Row>
                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                <Row>Город: {masterinfo.Town}</Row>
                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                            </div>
                        } title={masterinfo.Username} trigger="click">
                            <Tag
                                onClick={(e) => GetDataMaster(data.Master_id)}>Мастер
                            </Tag></Popover>
                    </div>

                    <div style={{paddingTop:"15px"}}>Модель: {data.Model}</div>
                    <div>Производитель: {data.Manufacturer}</div>
                    <div>IMEI: {data.IMEI_id}</div>
                    <div>Тел.: {data.Phone_number}</div>
                    <div>Сер. номер: {data.Serial_number}</div>
                    <div style={{paddingTop:"10px"}}>Дут. модель: {data.Fuel_level_sensor_model}</div>
                    <div>Дут. производитель: {data.Fuel_level_sensor_manufacturer}</div>
                    <div>Дут. серийник: {data.Fuel_level_sensor_serial}</div>


                </div>
            )
        }
    }

    useEffect(() => {
        document.body.style = 'background: black;';
        const script = document.createElement('script');


        tg.expand();

        const paramsUrl = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        tokenset(paramsUrl.token)

        fetch(Apiurl + "/transport/history?" + new URLSearchParams({
            token: paramsUrl.token,
            VIN: paramsUrl.VIN
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                mashistoryset(data.data.reverse())
            })


    }, [])


    return <Space type="flex" size='middle' justify="center" align="center" direction="vertical"
                  style={{padding: "20px"}}>

        {mashistory.map((data, index) =>

            (<Card style={{width: "95vw"}}
                   title={<div>{data.ID.split(":")[0] === "TACHOGRAPHSKZI" ? "Установка тахографа с СКЗИ" :
                       data.ID.split(":")[0] === "TACHOGRAPHEU" ? "Установка тахографа европейский" :
                           data.ID.split(":")[0] === "TERMINAL" ? "Установка терминала" :
                               data.ID.split(":")[0] === "TACHOGRAPHSKZICHANGE" ? "Замена блока СКЗИ" :
                                   data.ID.split(":")[0] === "TACHOGRAPHCALIBRATION" ? "Калибровка тахографа" : ""}</div>}>
                    <Space direction={"vertical"}>
                        <div><b>ID:</b> <span style={{fontSize: "12px"}}>{data.ID}</span></div>

                        <div>
                            <div><b>Тип. работ:</b> {
                                data.ID.split(":")[0] === "TACHOGRAPHSKZI" ? "Установка тахографа с СКЗИ" :
                                    data.ID.split(":")[0] === "TACHOGRAPHEU" ? "Установка тахографа европейский" :
                                        data.ID.split(":")[0] === "TERMINAL" ? "Установка терминала" :
                                            data.ID.split(":")[0] === "TACHOGRAPHSKZICHANGE" ? "Замена блока СКЗИ" :
                                                data.ID.split(":")[0] === "TACHOGRAPHCALIBRATION" ? "Калибровка тахографа" : ""

                            }</div>

                        </div>
                        <Collapse
                            style={{Width: "100%"}}
                            items={[{
                            key:1,
                            label:"Доп. Информация",
                            children: GetInfo(data)
                        }]}>

                        </Collapse>
                        <div>
                            {data.Calibration_date ? <div><b>Дата
                                калибровки:</b> {format(new Date(data.Calibration_date), 'dd.MM.yyyy HH:mm:ss')}
                            </div> : ""}
                            {data.Calibration_date_period ? <div><b>Дата окон.
                                калибровки:</b> {format(new Date(data.Calibration_date_period), 'dd.MM.yyyy HH:mm:ss')}
                            </div> : ""}
                        </div>


                        <div><b>Дата оказания
                            услуги:</b> <a>{format(new Date(data.Date_of_service), 'dd.MM.yyyy HH:mm:ss')}</a></div>
                        <Image.PreviewGroup>
                            <div>
                                <Space type="flex" wrap size='small' justify="center" align="middle"
                                       direction="horizontal"
                                       style={{paddingTop: "20px"}}>

                                    {data.Signed_statement_photo ? <Image width={100}
                                                                          src={Apiurl + "/photo/" + data.Signed_statement_photo}/> : ""}

                                    {data.Connection_speed_sensor_photo ? <Image width={100}
                                                                          src={Apiurl + "/photo/" + data.Connection_speed_sensor_photo}/> : ""}
                                    {data.Connection_sensor_photo ? <Image width={100}
                                                                          src={Apiurl + "/photo/" + data.Connection_sensor_photo}/> : ""}
                                    {data.Back_panel_photo ? <Image width={100}
                                                                          src={Apiurl + "/photo/" + data.Back_panel_photo}/> : ""}
                                    {data.Power_photo ? <Image width={100}
                                                                          src={Apiurl + "/photo/" + data.Power_photo}/> : ""}
                                    {data.Photo_power ? <Image width={100}
                                                               src={Apiurl + "/photo/" + data.Photo_power}/> : ""}
                                    {data.Photo_installation ? <Image width={100}
                                                               src={Apiurl + "/photo/" + data.Photo_installation}/> : ""}



                                </Space>
                            </div>

                        </Image.PreviewGroup>
                    </Space>


                </Card>
            ))

        }
    </Space>;

}