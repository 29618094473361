import {Component, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card, Row, Select, Space} from "antd";
import Apiurl from "../ConstURL";
import Header from "../components/Header";


export default function Mainpage() {


    return (
        <div>
            <Header/>
        <Space type="flex" size='middle' justify="center" align="middle" direction="vertical" style={{padding: "20px"}}>

            <Card>MAIN</Card>

        </Space>
        </div>
    );

}