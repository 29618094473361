import {Component, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Card,
    Col,
    Collapse,
    Descriptions,
    Divider,
    Input,
    Popover,
    Row,
    Select,
    Image,
    Space,
    Spin,
    Tag, FloatButton, Avatar, message
} from "antd";
import Apiurl from "../ConstURL";
import {Navigate, Outlet} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {format} from 'date-fns'
import {
    CheckOutlined, CloseOutlined,
    FilePdfFilled,
    FilePdfOutlined,
    FilePdfTwoTone,
    MinusCircleOutlined, MinusOutlined,
    StopOutlined
} from "@ant-design/icons";
import Header from "../components/Header";


export default function Transport() {
    const [transports, transportsset] = useState([])
    const [hasmore, hasmoreset] = useState(false)
    const [towns, townsset] = useState([])

    const [masterinfo, masterinfoSet] = useState({})

    const [selectedvin, selectedvinset] = useState("")


    function GetTerminalsDatCurrentTs(transportid) {
        const idx = transports.findIndex((el) => el.Vin_number === transportid)
        let workmas = transports.slice()
        for (const i of workmas[idx].Terminal_mas) {

        }

    }

    function handleChange(e) {
        fetch(Apiurl + "/transport/alltransport?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            vin: e.target.value,
            skip: 0,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                selectedvinset(e.target.value)
                if (data.allcount > 15) {
                    hasmoreset(true)
                }

                transportsset(data.data)
            })
    }

    async function GetDataMaster(userid) {
        await fetch(Apiurl + "/user/findid?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            userid: userid,
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data['status']) {
                    masterinfoSet(data['data'])
                }

            })

    }

    function DeleteElementYess(id) {
        const idx = transports.findIndex((el) => el.Vin_number === id)
        let newusers = transports.slice()
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        transportsset(newusers)

        fetch(Apiurl + "/transport/delete?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            VIN: id
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status){
                    let newusers = transports.slice()
                    console.log(newusers)
                    newusers.splice(idx,1)
                    console.log(newusers)
                    transportsset(newusers)
                    message.success("ТС удалено!")
                }else {
                    message.error("ТС не удалено!")
                }

            })




    }
    function DeleteElementYesorNo(id) {

        const idx = transports.findIndex((el) => el.Vin_number === id)
        let newusers = transports.slice()
        console.log(newusers[idx].DeleteYesorNo)
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        console.log(newusers[idx].DeleteYesorNo)
        transportsset(newusers)

    }
    function fetchMoreData() {
        fetch(Apiurl + "/transport/alltransport?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            vin: selectedvin,
            skip: transports.length,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.data)


                let newusers = transports.slice()


                transportsset([...newusers, ...data.data])
                if (data.allcount > transports.length) {
                    hasmoreset(true)
                } else {
                    hasmoreset(false)
                }
            })
    }

    useEffect(() => {
        fetch(Apiurl + "/transport/alltransport?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 15
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.data)
                transportsset(data.data)
            })


        fetch(Apiurl + "/towns?token=" + localStorage.getItem("token"))
            .then(response => {
                return response.json()
            })
            .then(data => {
                let masready = []
                masready.push({value: "", label: "-"})
                data.data.map(town => (
                    masready.push({value: town, label: town})
                ))
                townsset(masready)
            })


    }, [])

    return (
        <div>
            <Header/>
            <Row style={{padding: "20px"}}>
                <Space size={35}>
                    <Input size={"large"} addonBefore={"VIN"} onChange={handleChange} placeholder={"VIN"}/>

                    {/*<Col span={12}>*/}
                    {/*    <Select*/}
                    {/*        defaultValue="-"*/}
                    {/*        style={{width: 200}}*/}
                    {/*        // onChange={handleChange}*/}
                    {/*        options={towns}*/}
                    {/*    />*/}
                    {/*</Col>*/}

                </Space>
            </Row>
            <InfiniteScroll
                dataLength={transports.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <div style={{padding: 10, paddingBottom: 25}}><Spin size={"large"}></Spin></div>
                </div>}
                endMessage={
                    <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: 25}}><Card
                        size={"small"}>Больше нет</Card></div>
                }
            >
                <Space type="flex" wrap size={35} justify="center" align="middle" direction="vertical"
                       style={{width: "100%", paddingTop: "20px",paddingBottom:"20px",paddingLeft:"2vw", paddingRight:"2vw"}}>

                    {transports.map((ts, index) => (
                            <Card
                                style={{width:"94vw"}}
                                key={ts.Vin_number}
                                  title={ts.Transport_manufacturer + " " + ts.Transport_model + "  |  " + ts.Transport_gos_number}
                                extra={
                                    (ts.Services_mas === null) ?
                                        (ts.DeleteYesorNo ? <Space ><Button shape={"circle"} onClick={(e) => DeleteElementYess(ts.Vin_number)} icon={<CheckOutlined />}></Button> <Button type={"primary"} shape={"circle"} icon={<CloseOutlined />} danger onClick={(e) =>DeleteElementYesorNo(ts.Vin_number)}></Button></Space> : <Button type={"primary"} danger shape={"circle"} icon={<MinusOutlined />} onClick={(e) =>DeleteElementYesorNo(ts.Vin_number)}></Button>)

                                        : (ts.Services_mas.length < 2 &&
                                            (ts.DeleteYesorNo ? <Space ><Button shape={"circle"}  onClick={(e) => DeleteElementYess(ts.Vin_number)} icon={<CheckOutlined />}></Button> <Button type={"primary"} shape={"circle"} icon={<CloseOutlined />} danger onClick={(e) =>DeleteElementYesorNo(ts.Vin_number)}></Button></Space> : <Button type={"primary"} danger shape={"circle"} icon={<MinusOutlined />} onClick={(e) =>DeleteElementYesorNo(ts.Vin_number)}></Button>)
                                        )
                                }
                            >

                                <Row>VIN: {ts.Vin_number}</Row>

                                {/*<Divider dashed/>*/}
                                <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Подробнее",
                                        children:
                                            <Descriptions column={1} size={"small"}>
                                                <Descriptions.Item label="Владелец">{ts.Owner_name}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Адрес владельца">{ts.Owner_addres}</Descriptions.Item>
                                                <Descriptions.Item label="Заказчик">{ts.Customer}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Адрес заказчика">{ts.Customer_addres}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Тип транспорта">{ts.Transport_type}</Descriptions.Item>

                                                <Descriptions.Item
                                                    label="Производитель">{ts.Transport_manufacturer}</Descriptions.Item>
                                                <Descriptions.Item label="Модель">{ts.Transport_model}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Год выпуска">{ts.Transport_year}</Descriptions.Item>
                                                <Descriptions.Item label="Одометр">{ts.Odometer}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Гос. номер">{ts.Transport_gos_number}</Descriptions.Item>

                                            </Descriptions>
                                    }
                                    ]}/>

                                {ts.Tachograph_SKZI && <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Тахограф с СКЗИ ",
                                        children:
                                            <div>
                                                <Descriptions column={1} size={"small"} style={{marginBottom: 25}}>
                                                    <Descriptions.Item
                                                        label="Модель">{ts.Tachograph_SKZI_data.Model}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Производитель">{ts.Tachograph_SKZI_data.Manufacturer}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Серийный номер">{ts.Tachograph_SKZI_data.Serial_number}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Серийный СКЗИ">{ts.Tachograph_SKZI_data.Serial_SKZI}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Одометр">{ts.Tachograph_SKZI_data.Odometr}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="W">{ts.Tachograph_SKZI_data.W}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="K">{ts.Tachograph_SKZI_data.K}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="L">{ts.Tachograph_SKZI_data.L}</Descriptions.Item>

                                                    <Descriptions.Item
                                                        label="Дата калибровки">{format(new Date(ts.Tachograph_SKZI_data.Calibration_date), 'dd.MM.yyyy HH:mm:ss')}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Период калибровки">{format(new Date(ts.Tachograph_SKZI_data.Calibration_date_period), 'dd.MM.yyyy HH:mm:ss')}</Descriptions.Item>

                                                    <Descriptions.Item label="Оказал услугу">
                                                        <Popover onCl content={
                                                            <div>
                                                                <Row>Email: {masterinfo.Email}</Row>
                                                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                                                <Row>Город: {masterinfo.Town}</Row>
                                                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                                                                <Row>Оказал
                                                                    услугу: {format(new Date(ts.Tachograph_SKZI_data.Date_of_service), 'dd.MM.yyyy HH:mm:ss')}</Row>
                                                            </div>
                                                        } title={masterinfo.Username} trigger="click">
                                                            <Tag
                                                                onClick={(e) => GetDataMaster(ts.Tachograph_SKZI_data.Master_id)}>Мастер
                                                            </Tag><
                                                /Popover>
                                                    </Descriptions.Item>
                                                </Descriptions>

                                                <Image.PreviewGroup>
                                                    <div>
                                                    <Space type="flex" wrap size='small' justify="center" align="middle"
                                                           direction="horizontal"
                                                           style={{padding: "20px"}}>
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_SKZI_data.Connection_speed_sensor_photo}/>
                                                        <Image
                                                            width={100}
                                                            src={Apiurl + "/photo/" + ts.Tachograph_SKZI_data.Connection_sensor_photo}
                                                        />
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_SKZI_data.Back_panel_photo}/>
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_SKZI_data.Power_photo}/>
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_SKZI_data?.Signed_statement_photo}/>
                                                    </Space>
                                                        </div>

                                                    {/*TODO:Вывод файлов
                                                    <Space type="flex" wrap size='small' justify="center" align="middle"*/}
                                                    {/*       direction="horizontal"*/}
                                                    {/*       style={{padding: "20px"}}>*/}
                                                    {/*    <Button icon={<FilePdfOutlined/>} size={"large"}> </Button>*/}
                                                    {/*</Space>*/}
                                                </Image.PreviewGroup>
                                            </div>
                                    }]}/>}


                                {ts.Tachograph_EU && <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Тахограф",
                                        children:
                                            <div>
                                                <Descriptions column={1} size={"small"} style={{marginBottom: 25}}>
                                                    <Descriptions.Item
                                                        label="Модель">{ts.Tachograph_EU_data.Model}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Производитель">{ts.Tachograph_EU_data.Manufacturer}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Серийный номер">{ts.Tachograph_EU_data.Serial_number}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Одометр">{ts.Tachograph_EU_data.Odometr}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="W">{ts.Tachograph_EU_data.W}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="K">{ts.Tachograph_EU_data.K}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="L">{ts.Tachograph_EU_data.L}</Descriptions.Item>

                                                    <Descriptions.Item
                                                        label="Дата калибровки">{format(new Date(ts.Tachograph_EU_data.Calibration_date), 'dd.MM.yyyy HH:mm:ss')}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Период калибровки">{format(new Date(ts.Tachograph_EU_data.Calibration_date_period), 'dd.MM.yyyy HH:mm:ss')}</Descriptions.Item>

                                                    <Descriptions.Item label="Оказал услугу">
                                                        <Popover onCl content={
                                                            <div>
                                                                <Row>Email: {masterinfo.Email}</Row>
                                                                <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                                                <Row>Город: {masterinfo.Town}</Row>
                                                                <Row>Telegram Id: {masterinfo.Telegram_id}</Row>
                                                                <Row>Оказал
                                                                    услугу: {format(new Date(ts.Tachograph_EU_data.Date_of_service), 'dd.MM.yyyy HH:mm:ss')}</Row>
                                                            </div>
                                                        } title={masterinfo.Username} trigger="click">
                                                            <Tag
                                                                onClick={(e) => GetDataMaster(ts.Tachograph_EU_data.Master_id)}>Мастер
                                                            </Tag><
                                                /Popover>
                                                    </Descriptions.Item>
                                                </Descriptions>

                                                <Image.PreviewGroup>
                                                    <Space type="flex" wrap size='small' justify="center" align="middle"
                                                           direction="horizontal"
                                                           style={{padding: "20px"}}>
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_EU_data.Connection_speed_sensor_photo}/>
                                                        <Image
                                                            width={100}
                                                            src={Apiurl + "/photo/" + ts.Tachograph_EU_data.Connection_sensor_photo}
                                                        />
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_EU_data.Back_panel_photo}/>
                                                        <Image width={100}
                                                               src={Apiurl + "/photo/" + ts.Tachograph_EU_data.Power_photo}/>
                                                    </Space>
                                                </Image.PreviewGroup>
                                            </div>
                                    }]}/>}


                                {/*//============================*/}

                                {ts.Terminal && <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Терминалы",
                                        children:
                                            <div>
                                                {ts.Terminalsdata.map((terminal1, indexterm) =>
                                                    <Collapse key={terminal1.Id} style={{Width: "100%", marginTop: "15px"}}
                                                              items={[{
                                                                  key: 1,
                                                                  label: "Терминал " + (indexterm + 1),
                                                                  children:
                                                                      <div>
                                                                          <Descriptions column={1} size={"small"}
                                                                                        style={{marginBottom: 25}}>

                                                                              <Descriptions.Item
                                                                                  label="Производитель терминала">{terminal1.Manufacturer}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Модель терминала">{terminal1.Model}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="IMEI">{terminal1.IMEI_id}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Номер телефона">{terminal1.Phone_number}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Серийный номер">{terminal1.Serial_number}</Descriptions.Item>

                                                                              <Descriptions.Item
                                                                                  label="Производитель ДУТ">{terminal1.Fuel_level_sensor_manufacturer}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Модель ДУТ">{terminal1.Fuel_level_sensor_model}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Серийный номер ДУТ">{terminal1.Fuel_level_sensor_serial}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Одометр">{terminal1.Odometr}</Descriptions.Item>
                                                                              <Descriptions.Item
                                                                                  label="Дата установки">{format(new Date(terminal1.Date_of_service), 'dd.MM.yyyy HH:mm:ss')}</Descriptions.Item>

                                                                              <Descriptions.Item label="Оказал услугу">
                                                                                  <Popover onCl content={
                                                                                      <div>
                                                                                          <Row>Email: {masterinfo.Email}</Row>
                                                                                          <Row>Телефон: {masterinfo.Phonenumber}</Row>
                                                                                          <Row>Город: {masterinfo.Town}</Row>
                                                                                          <Row>Telegram
                                                                                              Id: {masterinfo.Telegram_id}</Row>
                                                                                          <Row>Оказал
                                                                                              услугу: {format(new Date(ts.Tachograph_SKZI_data.Date_of_service), 'dd.MM.yyyy HH:mm:ss')}</Row>
                                                                                      </div>
                                                                                  } title={masterinfo.Username}
                                                                                           trigger="click">
                                                                                      <Tag
                                                                                          onClick={(e) => GetDataMaster(terminal1.Master_id)}>Мастер
                                                                                      </Tag><
                                                /Popover>
                                                                              </Descriptions.Item>
                                                                          </Descriptions>
                                                                          <Image.PreviewGroup>
                                                                              <Space type="flex" wrap size='small'
                                                                                     justify="center" align="middle"
                                                                                     direction="horizontal"
                                                                                     style={{padding: "20px"}}>

                                                                                  <Image
                                                                                      width={100}
                                                                                      src={Apiurl + "/photo/" + terminal1.Photo_installation}
                                                                                  />
                                                                                  <Image width={100}
                                                                                         src={Apiurl + "/photo/" + terminal1.Photo_power}/>

                                                                              </Space>
                                                                          </Image.PreviewGroup>
                                                                      </div>

                                                              }]}/>
                                                )}
                                            </div>
                                    }]}/>}

                                <Divider dashed/>
                                {ts.Tachograph_EU && <Tag color={"green"}>Тахограф</Tag>}


                                {ts.Tachograph_SKZI && <Tag color={"green"}>Тахограф с СКЗИ</Tag>}
                                {ts.Terminal && <Tag color={"blue"}>Терминал ({ts.Terminal_mas.length})</Tag>}

                            </Card>

                        )
                    )}

                </Space>
                <FloatButton.BackTop/>
            </InfiniteScroll>

        </div>
    );

}