import {Component, useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card, ConfigProvider, Form, Input, Layout, message, Row, Select, Space} from "antd";
import Apiurl from "../ConstURL";
import {ArrowLeftOutlined, CloseOutlined, RollbackOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";


export default function Authpage() {


    const [form] = Form.useForm()
    const Navigate = useNavigate()

    const onFinish = (values) => {
        var token = localStorage.getItem("token")

        fetch(Apiurl + "/admin/createusertoken?" + new URLSearchParams({
            token: token,
            username: values.username,
            phonenumber: values.phone,
            email: values.email,
            town: values.town,
            pass: values.password,
            tgid: values.tgid
        }))
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if (data['created']) {

                    message.success("Пользователь создан!", 9)
                    form.resetFields()
                    // e.preventDefault();
                    // e.target.reset();

                } else {
                    message.error("Пользователь не добавлен!")
                }
            }
        )

        //window.alert("Авторизация прошла успешно!")
    };

    const validatePhone = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^((\+7|7|8)+([0-9]){10})$/
            );
    };
    const validatePhoneNumber = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            if (!value) {
                //await reject('Введите телефон!')
            } else if (!/^((\+7|7|8)+([0-9]){10})$/.test(value)) {
                await reject('Это не номер телефона')
            } else {
                await resolve()
            }
        })
    }
    const validateEmailAvailable = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            var token = localStorage.getItem("token")


            if (!value) {
                //await reject('Введите почту!')
            }
                // else if (!/^((\+7|7|8)+([0-9]){10})$/.test(value)) {
                //     await reject('Это не номер телефона')
            // }
            else {
                const Check = await fetch(Apiurl + "/admin/available?token=" + token + "&email=" + value)
                    .then((response) => response.json()
                    ).then((json) => {
                        return json
                    })
                if (Check['available']) {
                    await resolve()
                } else {
                    await reject('Почта занята!')
                }

            }
        })
    }


    const [Createduser, setCreated] = useState(false)
    const [validphone, setvalidphone] = useState("error")

    if (!Createduser) {


        return (<div>
                <Header/>
                <Row type="flex" justify="center" align="middle" style={{minHeight: '50vh', paddingTop: '20px'}}>
                    <Card type="flex" title={"Создать пользователя"} justify="center" align="middle" extra={<Button shape={"circle"} onClick={(e)=>Navigate('/users')} size="large"  icon={<RollbackOutlined/>}></Button>}>


                        {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                        {/*Create User*/}
                        <Form
                            layout='vertical'
                            style={{justifyContent: 'center'}}
                            name="basic"
                            form={form}
                            wrapperCol={{
                                span: '75%',
                            }}
                            style={{
                                width: '325px',
                                verticalAlign: 'middle',
                                maxWidth: '75%',

                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            // onFinishFailed={}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="ФИО"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите ФИО!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                hasFeedback
                                rules={[
                                    {

                                        required: true,
                                        message: 'Введите Email!',
                                    },
                                    {type: "email", message: "Это не Email!"},
                                    {validator: validateEmailAvailable}
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Пароль"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите ваш пароль!',
                                    },
                                ]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label="Номер телефона"
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите Номер телефона!',
                                    },
                                    {validator: validatePhoneNumber}

                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item label="Город"
                                       name="town">
                                <Select rules={[
                                    {
                                        required: true,
                                        message: 'Выберите город!',
                                    },
                                ]}>
                                    <Select.Option value="Иркутск">Иркутск</Select.Option>
                                    <Select.Option value="Новосибирск">Новосибирск</Select.Option>
                                    <Select.Option value="Хабаровск">Хабаровск</Select.Option>
                                    <Select.Option value="Улан-Удэ">Улан-Удэ</Select.Option>
                                    <Select.Option value="Чита">Чита</Select.Option>
                                    <Select.Option value="Новороссийск">Новороссийск</Select.Option>
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label="Telegram Id"
                                name="tgid"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите Telegram Id!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>


                            <Form.Item
                                wrapperCol={{

                                    span: 20,
                                }}
                            >
                                <Button type="primary" htmlType="submit" size='large'>
                                    Добавить пользователя
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Row>
            </div>
        );
    } else {
        return (

            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center', paddingTop: '25px'}}>
                <Button type='primary' size='large' shape='round'
                        onClick={(e) => setCreated(!Createduser)}>Создать</Button>
                !!!!!
            </Space>

        )
    }

}