
import './App.css';

import {Link, Route, Router, Routes} from "react-router-dom";


import Authpage from './pages/Authpage';
import Createuser from './pages/Createuser';
import Mainpage from './pages/Mainpage';
import Users from "./pages/Users";
import Header from "./components/Header";
import Transport from "./pages/Transport";
import {Button} from "antd/lib";
import { AndroidOutlined } from '@ant-design/icons';
import PrivateRoutes1 from './funcs/CheckAuth'
import {AuthProvider} from "./funcs/Authcontext";
import {ConfigProvider, Layout, theme} from "antd";


import ruRu from 'antd/locale/ru_RU';

import {Footer} from "antd/es/modal/shared";
import staticusers from "./pages/Staticusers";
import Staticusers from "./pages/Staticusers";
import ServiceChangeSKZI from "./pages/ServiceChangeSKZI"
import ServiceCalibrateTac from "./pages/ServiceCalibrateTac"
import GetTSHistory from "./pages/GetTSHistory"

function Check()
{
  return(<div>Проверка</div>);

}
function notifyme1(){
    window.alert("!!!")
}

function App() {

  return (

      <ConfigProvider
          locale={ruRu}
          theme={{
              algorithm: theme.darkAlgorithm,
              "token": {

                  "colorPrimary": "#a017ff",
                  "colorInfo": "#a017ff",
                  "fontSize": 16
              },

          }}
      >

          <Layout style={{minHeight:"100vh"}}>
          <AuthProvider>

              <Routes>
                  <Route exact path='/webapptg/changeskzi' element={<ServiceChangeSKZI/>}/>
                  <Route exact path='/webapptg/calibration' element={<ServiceCalibrateTac/>}/>
                  <Route exact path='/webapptg/ts-history' element={<GetTSHistory/>}/>

                  <Route path="/login" element={<Authpage/>}/>
                  <Route element={<PrivateRoutes1 />}>

                      <Route exact path='*' element={<Mainpage/>}/>
                      <Route path='/addusers' element={<Createuser/>}/>
                      <Route path='/users' element={<Users/>}/>
                      <Route path='/transport' element={<Transport/>}/>
                      <Route path='/users/static' element={<Staticusers/>}/>
                  </Route>
              </Routes>

          </AuthProvider>
              </Layout>
      </ConfigProvider>
      );
    // <div className="App">


        {/*<Header/>*/}
        {/*<Routes>*/}

        {/*    <Route element={<PrivateRoutes1/>}>*/}
        {/*        <Route exact path='*' element={<Mainpage/>}/>*/}
        {/*        <Route path='/addusers' element={<Createuser/>}/>*/}
        {/*    </Route>*/}
        {/*    <Route path='/login' element={<Authpage/>}/>*/}
        {/*</Routes>*/}
    {/*</div>*/}

}

export default App;
